import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const CtaContainer = ({ variant, divider, className, children }) => {
    return (
        <div
            className={`c-cta-container${
                variant && ` c-cta-container--${variant}`
            }${divider ? ` c-cta-container--divider` : ''}${
                className && ` ${className}`
            }`}
        >
            {children}
        </div>
    )
}

CtaContainer.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['', 'centered', 'right']),
    /**
     * If divider is present
     */
    divider: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

CtaContainer.defaultProps = {
    variant: '',
    divider: false,
    className: '',
    children: '<p>Buttons go here</p>'
}

export default CtaContainer
